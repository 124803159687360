.App {
  font-family: "Ubuntu", sans-serif;
}

.app-theme-white.app-container {
  background: #70ad47;
  /*#d1edb5;*/
}

.card.mb-3 {
  margin: 5.5rem !important;
}

label[for="lblLogin"] {
  font-weight: bold;
  font-size: x-large;
}

label[for="lblForgotPassword"] {
  font-style: italic;
}

.btn-primary:hover {
  color: #fff;
  background-color: #70ad47 !important;
  border-color: #005800 !important;
}

.btn-primary {
  color: #fff;
  background-color: #70ad47 !important;
  border-color: #005800 !important;
}

.language-switch-title {
  text-align: center;
}

.imgLogo {
  text-align: center;
  margin-top: 10%;
}

.btn-group,
.btn-group-vertical {
  margin: auto;
}

body {
  /* background-color: #d1edb5 !important; */
  background-color: #fff !important;
}

.home.card {
  background-color: transparent;
  box-shadow: none;
  border: none;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #eeeeee !important;
}

img#qr_code_image_display {
  width: 100%;
  /*height: 250px;*/
  margin-top: 20px;
  margin-left: 20px;
}

.btn {
  font-size: 13px !important;
}

.col-form-label {
  font-size: 15px !important;
  font-weight: 500;
  text-align: left;
}

.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #203864;
  --bs-btn-border-color: #203864;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #203864;
  --bs-btn-hover-border-color: #203864;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #203864;
  --bs-btn-active-border-color: #203864;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #203864;
  --bs-btn-disabled-border-color: #203864;
}

button.mb-2.me-2.side.btn.btn-info.active {
  background-color: #203864 !important;
  border-color: #203864 !important;
}

.btn-primary1:hover {
  color: #fff !important;
  background-color: #005800 !important;
  border-color: #005800 !important;
}

.btn-primary1 {
  color: #fff !important;
  background-color: #005800 !important;
  border-color: #005800 !important;
}

.btn-cancel1:hover {
  color: #fff !important;
  background-color: #ff0000 !important;
  border-color: #ff0000 !important;
}

.btn-cancel1 {
  color: #fff !important;
  background-color: #ff0000 !important;
  border-color: #ff0000 !important;
}

.table {
  /* --bs-table-bg: #ccc !important; */
  font-size: 12px;
}

tr.thead-tr {
  background-color: #ccc !important;
  background: #ccc !important;
}

th,
thead,
tr {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  color: #fff !important;
  background: #548235 !important;
}

h4.d-flex.justify-content-left.bold {
  color: #005800;
  font-weight: 700;
}

.btn-primary2:hover {
  color: #fff !important;
  background-color: #70ad47 !important;
  border-color: #70ad47 !important;
}

.btn-primary2 {
  color: #fff !important;
  background-color: #70ad47 !important;
  border-color: #70ad47 !important;
}

h4.light.d-flex.justify-content-left.bold.mb-4.mt-4 {
  color: #548235;
  font-weight: 700;
}

.form-control {
  border: 1px solid #ccc !important;
}

/* .app-container{
  min-height: auto;
} */

.displayNone {
  display: none;
}

.form-check-input[type="radio"] {
  border: 1px solid #005800 !important;
}

button.btn.btn-primary {
  background-color: #548235 !important;
  border-radius: 5px;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  /* Align buttons to the right */
  margin-top: 10px;
  /* Adjust margin as needed */
}

.menu-header-subtitle {
  font-size: 31px;
  margin: 5px 0 0;
  opacity: 100%;
}

.card-shadow-primary.card.bg-primary {
  background-color: #f88b06 !important;
  padding: 20px;
  text-align: center;
  border-radius: 0px !important;
  min-height: 155px;
  max-height: 155px;
}

.card-shadow-primary.card.bg-focus {
  background-color: #4286f5 !important;
  padding: 20px;
  text-align: center;
  border-radius: 0px !important;
  min-height: 155px;
  max-height: 155px;
}

.card-shadow-primary.card.bg-dark {
  background-color: #423a8e !important;
  padding: 20px;
  text-align: center;
  border-radius: 0px !important;
  min-height: 155px;
  max-height: 155px;
}

.menu-header-title {
  font-size: 20px;
}

.dash {
  border: 1px solid #000;
  padding: 0px;
}

.card-shadow-primary.card.bg-black {
  background-color: #000000 !important;
  padding: 20px;
  text-align: center;
  border-radius: 10px !important;
  min-height: 120px;
  max-height: 120px;
}

.card-shadow-primary.card.bg-green {
  background-color: #198754 !important;
  padding: 20px;
  text-align: center;
  border-radius: 0px !important;
  min-height: 120px;
  max-height: 120px;
  z-index: 3;
}

.card-shadow-primary.card.bg-red {
  background-color: #d32b2b !important;
  padding: 20px;
  text-align: center;
  border-radius: 0px !important;
  min-height: 120px;
  max-height: 120px;
  z-index: 2;
}

.addplus {
  float: right;
  margin-top: -70px !important;
  z-index: 0 !important;
  font-weight: bold !important;
  font-size: 50px !important;
  margin-right: -47px !important;
}

.btn-outline-success:hover {
  color: #000 !important;
  background-color: transparent !important;
  border-color: #70ad47 !important;
}

.btn-outline-success {
  color: #000 !important;
  background-color: transparent !important;
  border-color: #70ad47 !important;
  padding: 0px !important;
}

span.badge.badge-pill.badge-light {
  background-color: #70ad47 !important;
  width: 30px;
  height: 30px;
  background-color: #007bff;
  border-radius: 50%;
  color: #ffffff;
  font-weight: bold;
  margin-left: 0px;
  margin-right: 0px;
}

.badge svg {
  margin-top: 7px;
}

.number_card {
  z-index: 9;
  margin-top: 50px;
}

.calender.row {
  z-index: 10;
  height: 30px;
  position: relative;
}

table .form-check-input:checked[type="radio"] {
  background-color: #198754 !important;
}

Form .form-check-input:checked[type="radio"] {
  background-color: #198754 !important;
}

.modal {
  margin-top: 10%;
}

.modal-header {
  border-bottom: none !important;
  background-color: #fff;
}

.modal-footer {
  border-top: none !important;
  background-color: #fff;
}

h5.d-flex.justify-content-left.bold {
  color: #005800;
  font-weight: 700;
}

button.btn.btn-primary4 {
  background-color: #548235 !important;
  border-radius: 5px;
  padding: 5px 20px;
  font-size: 17px !important;
  color: #fff;
}

#pdf-zoom-out,
#pdf-zoom-in,
#pdf-zoom-reset,
#pdf-toggle-pagination,
#pdf-download {
  display: none !important;
}
.myHeading {
  font-size: 1.5rem;
}
.log_name.col-md-7 {
  margin-top: 1px;
  margin-bottom: 2px;
}
.side-nav-container {
  background-color: #005800;
  width: auto !important;
}
/* Tablet view */
@media (max-width: 768px) {
  .myHeading {
    font-size: 1.5rem;
    text-wrap: nowrap;
  }
  .log_name {
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
  }
  .desktop_language {
    display: none;
  }
  .mobile_language .menu-language-switch {
    display: none;
  }
  button.navbar-toggler.collapsed {
    margin-right: 10px;
  }
  .search-bar-container {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center !important;
    min-width: 200px !important;
    position: relative !important;
    padding-top: 0px !important;
    padding-bottom: 10px;
  }
  h6.lang_swtich.text-red {
    color: red;
    font-weight: bold;
  }
  h6.lang_swtich.text-blue {
    color: #00308f;
    font-weight: bold;
  }
  div#basic-navbar-nav {
    z-index: 10;
    text-align: left;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
    min-height: 56px !important;
    padding-top: 11px !important;
    background-color: #b4e187 !important;
    width: 100%;
  }
  .nav-item.dropdown {
    width: 100%;
  }
  .text-mv-left {
    text-align: left !important;
  }
  .toggle-switch {
    position: relative;
    width: 75px;
    display: inline-block;
    text-align: left;
    top: 0px;
  }
  .dropdown-menu .dropdown-item {
    color: #fff;
    background-color: #005800;
    font-size: 15px;
    font-weight: 600 !important;
  }
  .dropdown-menu.show {
    display: contents;
  }
}

@media only screen and (min-width: 768px) {
  .mobile_language {
    display: none;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1280px) {
  .mobile_language .menu-language-switch {
    display: none;
  }
}

/* Mobile view */
@media (max-width: 576px) {
  .myHeading {
    font-size: 1.30rem;
    text-wrap: balance;
  }
  img.rounded.image-btn.d-block {
    margin-top: 10px;
}
.log_name.col-md-7{
  margin-bottom: 10px;
}
h6.lang_swtich.text-blue {
  color: #00308F;
  font-weight: bold;
  padding-right: 10px;
}
.navbar-expand-lg .navbar-nav .nav-link {
  background-color: #b4e187;
  border-bottom: 1px solid #005800;
  font-size: 20px !important;
}
/* .mobile_language.col-md-3 {
  margin-bottom: 10px;
}
button.navbar-toggler {
  margin-bottom: 8px;
} */
  h4.d-flex.justify-content-center.bold {
    color: #005800;
    font-weight: 700;
    text-align: center;
  }
  .search-bar-container {
    padding-top: 10px;
    padding-bottom: 20px;
    width: 85% !important;
  }
  .dropdown-menu .dropdown-item {
    color: #fff !important;
    background-color: #005800 !important;
    font-size: 15px;
    font-weight: 600 !important;
  }
  .dropdown-menu.show {
    display: contents !important;
  }
  .dropdown-menu .dropdown-item {
    color: #fff;
    font-size: 16px !important;
    font-weight: 600 !important;
    font-family: "Ubuntu", sans-serif !important;
  }
  .navbar-toggler:hover {
    margin-right: 10px !important;
  }
  .navbar-toggler:focus {
    box-shadow: none !important;
  }
  .navbar-toggler {
    margin-right: 10px;
  }
  .side-nav-container {
    background-color: #fff;
    width: auto !important;
  }
  .nav-upper > button {
    background-color: transparent;
    color: #fff !important;
  }
  .menu-item {
    height: auto !important;
    display: flex;
    color: var(--light);
    text-decoration: none;
    margin: auto 10px !important;
    font-size: 10px;
    border-bottom: 1px solid white;
  }
  .nav-menu {
    grid-template-rows: repeat(7, 1fr);
    margin-top: 0px !important;
  }
  .menu-item-p {
    width: 100%;
    margin-top: 6px;
    margin-bottom: 6px;
    align-self: center;
  }
  .menu-button {
    display: none;
  }
  .table-card.card {
    margin: 0px;
    padding: 0px;
    box-shadow: none;
  }
  .table-card.card > .card-body {
    margin: 0px;
    padding: 3px;
  }
  .table thead th,
  .table td {
    vertical-align: middle;
    text-wrap: nowrap;
  }
  h4.bold.mb-4 {
    color: #005800;
    font-weight: 700;
    font-size: 20px;
    text-align: center;
  }
  .form-row {
    width: 100% !important;
  }
  .mb-low > label.col-form-label {
    font-weight: bold;
    padding: 10px 2px;
  }
  label#qr_code_data_display {
    text-align: center;
  }
}
.box-shad-none.card {
  box-shadow: none;
  border: none;
}
.mb-low > label.col-form-label {
  font-weight: bold;
  padding: 10px 2px;
}
.nav-upper > button {
  background-color: transparent;
  color: #fff !important;
}
h6.lang_swtich {
  color: #fff;
  margin-top: 10px;
  padding: 0px 6px;
}
.toggle-switch {
  position: relative;
  width: 75px;
  display: inline-block;
  text-align: left;
  top: 6px;
}
.switch {
  display: block;
  width: 24px;
  margin: 5px;
  background: #005800 !important;
}
h4.bold.mb-4 {
  color: #005800;
  font-weight: 700;
}
.table > thead {
  vertical-align: bottom;
  text-align: left;
}
a.nav-link {
  border-right: 1px solid #005800 !important;
}
/* .navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link > .active {
  color: #fff !important;
  background-color: #005800 !important;
}
.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
  color: #fff !important;
  background-color: #005800 !important;
}
.navbar-nav .nav-link.active {
  color: #fff !important;
  background-color: #005800 !important;
} */
button.bottom-bar-btn.active {
  background-color: #000;
  color: #fff;
}
span.text-block {
  color: #000;
}
span.light-green {
  color: #368600;
}
.mrfwaste-body {
  display: grid;
  background-color: #fff !important;
  /* let content auto to occupy remaining height and pass value in fit-content with min-height for header and footer */
  grid-template-rows: fit-content(8rem) auto fit-content(8rem);
  grid-template-areas: "header" "main" "footer";
  /* height: 100vh !important; */
}

.mrfwaste-header {
  /* background-color: yellow; */
  grid-area: header;
  /* height: 150px; */
}

.mrfwaste-content {
  grid-area: main;
  max-height: calc(100vh - 215px);
  overflow-y: scroll !important;
  background-color: #fff !important;
  margin: 10px;
}

.mrfwaste-footer {
  background-color: #fff !important;
  grid-area: footer;
  /* height: 85px; */
  width: 100vw;
  position: absolute;
  bottom: 0px;
}

.mrfwaste-mt-1 {
  background-color: #d1edb5 !important;
  margin-top: 0px !important;
  min-width: 100vw !important;
  /* height: 150px; */
}

.mrfwaste-d-flex {
  max-height: 100%;
}
button.btn.btn-cancel1.active {
  background-color: #dc3545 !important;
}
button.btn-danger.btn.btn-cancel.active {
  background-color: #dc3545 !important;
}
/* .navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link > .active {
  background-color: #005800 !important;
  color: #fff;
}
a.nav-link.active {
  background-color: #005800 !important;
  color: #fff;
}
a.nav-link:focus {
  background-color: #005800 !important;
  color: #fff;
}
a.nav-link:visited {
  background-color: #005800 !important;
  color: #fff;
} */
/* .navbar-expand-lg .navbar-nav .nav-link.active{
  background-color: #005800 !important;
  color: #fff;
}
@media (min-width: 992px){
.navbar-expand-lg .navbar-nav .nav-link.active {
  background-color: #005800 !important;
  color: #fff;
}
}
.navbar-expand-lg .navbar-nav .nav-link.active{
  background-color: #005800 !important;
  color: #fff;
} */
/* .navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link > .active {
  color: #fff !important;
  background-color: #005800 !important;
} */
