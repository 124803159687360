.default-color-dark{
    background-color: #00695c;
}
.default-color{
    background-color: #2BBBAD;
}

 .amber-darken-3{
    background-color: #ff8f00;
    background-size: contain;
}

 .amber-lighten-4{
    background-color:#ffecb3
 }

 .light-green-accent-3{
     background-color:  #76ff03;
 }
 #div_qr_code_print_display{
text-align: center;
 }
 img#qr_code_image_display {
    width: 100%;
    margin-top: 20px;
    margin-left: 0px;
}