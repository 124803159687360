.color_navber {
  color: #5162dd;
}

.light-green darken-1 {
  color: #7cb342;
}

.navbar {
  width: 100%;
  /* padding: 10px; */
  /* position: fixed; */
  top: 0;
  transition: top 0.6s;
}

.navbar--hidden {
  top: -50px;
}
/* Add this CSS to your component or import a separate CSS file */
.language-buttons {
  display: flex;
  flex-direction: row;
}

.language-button {
  display: flex;
  flex-direction: row;
  align-items: center;
}

/* Media Query for smaller screens */
@media (max-width: 768px) {
  .language-buttons {
    flex-direction: column;
  }

  .language-button {
    margin-left: 0;
    margin-bottom: 10px;
  }
}

nav.navbar.navbar.navbar-expand-lg.navbar-light.bg-primary {
  background-color: #548235 !important;
}

nav.navbar.navbar.navbar-expand-lg.navbar-light.bg-secondary {
  background-color: #b4e187 !important;
  height: 55px !important;
}

a.nav-link {
  color: #005800 !important;
  font-weight: bold;
}

.dropdown-menu.show {
  /* padding: 10px; */
  /* margin: 5px; */
  background-color: #005800;
  box-shadow: none;
  border-radius: 0 !important;
  min-width: 100% !important;
  /* margin-left: 15px; */
}

.dropdown-item {
  padding: 10px !important;
}

.dropdown-menu::before,
.dropdown-menu::after {
  position: absolute;
  width: 0px;
  height: 0px;
  left: 0px;
  top: 0px;
  transform: rotate(45deg);
  border-radius: 4px;
  content: "";
  display: block;
  z-index: 5;
}

.header__pane.ms-3 {
  font-size: 12px;
}

.dropdown-menu .dropdown-item {
  color: #fff;
  font-size: 15px;
  font-weight: 600 !important;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #005800 !important;
}

.dropdown-item:hover {
  color: #fff;
  text-decoration: none;
  background-color: #005800;
}

.nav-item .nav-link {
  font-weight: bold;
  font-size: 22px;
}

.nav-link {
  font-weight: bold;
  font-size: 22px;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #005800 !important;
  color: #fff !important;
}

/* a.dropdown-toggle.nav-link:hover{
  background-color: #005800 !important;
  color:#fff !important;
} */
/* .show.dropdown.nav-item:hover {
  background-color: #005800;
  color:#fff !important;
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: #fff;
  background-color: #005800 !important;
} */
/* .navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: #fff !important;
  background-color: #005800 !important;
} */

a.dropdown-item {
  border-top: 1px solid #fff;
}

a.dropdown-item:first-child {
  border-top: none;
}

.dropdown-toggle::after {
  /* float: right; */
  margin-top: 7px;
}
.dropdown-toggle::after {
  margin-left: 10px;
}
.menu-language-switch {
  color: white;
  margin-top: 3px !important;
  text-align: center;
  font-size: 13px;
}

.dropdown-item {
  text-align: left !important;
}

.bsCheck {
  vertical-align: text-top;
}
.nav-item.dropdown {
  border-right: 1px solid #005800 !important;
}
.navbar-expand-lg .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
  min-height: 56px !important;
  padding-top: 11px !important;
}
.radio_check {
  text-align: center;
  margin-left: -18px !important;
  margin-top: -6px;
  z-index: 0 !important;
  color: #005800 !important;
}
.form-check-input:checked {
  background-color: #fff !important;
}

.rounded-img {
  border-radius: 50%;
  background-color: white;
  margin-left: 20px;
}
.btn-border.btn.btn-secondary {
  border: 2px solid #fff;
  background: transparent;
  color: #fff;
}
.image-btn {
  margin-left: 22px;
  margin-right: 22px;
}
button.btn.btn-outline-dark {
  color: #fff;
  border: 1px solid #fff;
  margin: 5px 20px !important;
  font-size: 20px !important;
  padding: 10px !important;
}
.input-wrapper {
  box-shadow: none !important;
  border: 1px solid #ccc !important;
  font-size: 1rem !important;
  border-radius: 5px !important;
}
.nav-link {
  font-size: 22px !important;
}
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link > .active {
  color: #fff !important;
  background-color: #005800 !important;
}
/* .navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
  color: #fff !important;
  background-color: #005800 !important;
} */
/* .navbar-nav .nav-link.show {
  color: #fff !important;
  background-color: #005800 !important;
} */
.navbar-nav .nav-link.active {
  color: #fff !important;
  background-color: #005800 !important;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show{
  background-color: #005800 !important;
  color: #fff !important;
}

.nav-dropdown {
  position: relative;
}

.nav-dropdown:hover .dropdown-menu {
  display: block;
}
